export default function () {
	
	$(document).ready(function(){
		$('#nav-icon-mobile').click(function(){
			$(this).toggleClass('open');
		});
		$('#nav-icon').click(function(){
			$(this).toggleClass('open-desktop');
		});
	});
}


