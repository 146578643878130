export default function () {
	$( document ).ready(function() {

		$( ".cross" ).hide();
		$('.menu:not(#menu-language-switcher)').hide();
		//$(".side-wrapper").css({opacity: "0"}, 800);

		$( ".c-nav__desktop" ).click(function() {

			$(".c-sidenav").toggleClass("sidenav-open");

			$(".side-wrapper").toggleClass("side-open ");
		});
	});
}

