export default function () {
	
	var fields = $('.wpcf7-form input:not([role="submit"]), .wpcf7-form textarea'),
		$label = $('label'),
		containers = $ ('.name-field-container, email-field-container, text-field-container');


    fields.focus(function() {

    	$(this).addClass('placeholder-on');
        $(this).parents().siblings('label').addClass('is-active');
     	$(this).closest('.form-row').addClass('border-change');
    })


    fields.blur(function() {

        var $this = $(this);

		if($this.val() == '') {
    		$(this).removeClass('placeholder-on');
            $(this).parents().siblings('label').removeClass('is-active');
			$(this).closest('.form-row').removeClass('border-change');
        }

    })

}


