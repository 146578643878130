import './modules/helpers';
import start from './modules/router';
import scroller from './modules/scroller';
import sidenav from './modules/sidenav';
import mobileMenu from './modules/mobileMenu';
import hamburger from './modules/hamburger';
import formanimation from './modules/formanimation';
import tableIndex from './modules/tableIndex';


scroller();
sidenav();
mobileMenu();
hamburger();
tableIndex();
formanimation();