export default function () {
	$( document ).ready(function() {

		$( ".cross" ).hide();
		$(".menu:not(#menu-language-switcher)").hide();

		$('.c-nav__mobile').click (function(){
			$('.background-circle').toggleClass('is-active');
			$(".c-nav__mobile__ul").toggleClass("big");
			$(".c-nav__mobile__li").toggleClass("popRight");
			$("html").toggleClass("locked");
		})



	});
}


